html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', 'Open Sans', 'Segoe UI', Arial, sans-serif;
  font-size: 0.9rem;
}

input,
textarea {
  padding: 0.5rem;
  /* font-size: 16px; */
  /* width: 100%; */
  /* display: block; */
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* input:focus {
  /* border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
} */

input.error,
textarea.error {
  border-color: red;
}

/* label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
} */

a.c9-brand.active,
a.c9-brand.active:focus,
a.c9-brand.active:hover {
  text-decoration: none;
  border: none;
}

a.active,
a.active:focus {
  text-decoration: none;
  border-bottom: 2px solid #555;
}

.nav.page-left-menu a {
  border-radius: 6px 0 0 6px;
}

.nav-item button.btn-link:focus {
  text-decoration: none;
}

.input-feedback {
  color: red;
  margin-top: 0.25rem;
}

.datepicker-full input {
  width: 100%;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 0.375rem 0.75rem;
}

.appt-filter {
  margin-bottom: 0.5rem;
}

.appt-time {
  text-decoration: underline;
  text-decoration-color: blue;
}

.slot-time-container {
  height: 300px;
  padding-right: 20px;
}

.default-style-timepicker {
  top: unset !important;
  bottom: unset !important;
  left: unset !important;
  right: unset !important;
  position: fixed !important;
  transform: unset !important;
}

.slot-padding {
  padding: 10px 0px;
  text-align: center;
}

.no-slot {
  background-color: #dddddd;
  /* position: relative; */
  /* z-index: 0; */
}

.slot-col-header {
  font-weight: bold;
  border-right: 1px solid black;
  border-left: 1px solid black;
  border-top: 1px solid black;
  box-shadow: 0 8px 6px -6px black;
  /* position: relative; */
  /* z-index: 0; */
}

div.slot-col-header {
  margin-right: 5px;
}

.slot-col-header :nth-child(2n) {
  background-color: #f1f2f3;
  border-right: 1px solid black;
  border-left: 1px solid black;
}

.slot-col-header> :nth-child(2n + 1) {
  background-color: #ddd;
}

.slot-col>.col {
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.slot-col> :first-child {
  border-left: 1px solid black;
}

.form-row {
  margin-bottom: 0.5rem;
  width: 100%;
}

.form-row input {
  width: 100%;
}

@font-face {
  font-family: 'password';
  font-style: normal;
  src: url(./assets/fonts/password.ttf);
}

.passwordFont:not(:placeholder-shown) {
  font-family: 'password';
}

.my-modal-lg {
  max-width: 80% !important;
}

.not-paid {
  color: red;
}

.paid {
  color: blue;
}

.has-breakup {
  text-decoration: underline;
  text-decoration-style: dotted;
  cursor: help;
}

.editable-booking-field:hover,
.editable-booking-field-empty {
  text-decoration: underline;
  text-decoration-color: red;
  cursor: pointer;
}

.editable-booking-field-empty {
  color: blue;
}

.event-outer-container {
  display: flex;
  flex-direction: row;
}

.event-error {
  color: red;
}

.event-pending {
  color: #999999;
}

.event-done {
  color: blue;
}

.event-container {
  display: flex;
  flex-direction: row;
}

.event-title-box {
  display: flex;
  flex-direction: column;
}

.event-text-box {
  text-align: center;
  min-width: 8rem;
}

.grey-caps {
  color: #999999;
  text-transform: uppercase;
}

.inline-force {
  display: inline !important;
}

.abs-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.abs-top-10 {
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translate(-50%, -50%);
}

.error {
  color: red;
  font-style: italic;
}

.margin-btm-1rem {
  margin-bottom: 1rem;
}

/*Hack to make showTimeSelect case to not have unnecessary left
padding
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding: 0;
  min-width: 10em;
} */

.react-datepicker__time-container {
  width: 100px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100px !important;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 110px !important;
}

.unsaved {
  background-color: #ffcc00;
}

.activeTab {
  cursor: default;
  color: black;
  font-weight: bold;
}

.inactiveTab {
  cursor: default;
  color: rgb(153, 153, 153);
  font-weight: bold;
}

@keyframes anneal {
  0% {
    background: #e25822;
  }

  100% {
    background: none;
  }
}

.anneal {
  animation: anneal 3s;
}

.disabled-bg {
  background-color: #eeeeee;
}

#userInfo {
  text-transform: capitalize;
}

.doc-badge h6 {
  padding: 0;
  margin: 0;
}

button.doc-badge-title {
  text-align: left;
  padding: 0;
  margin-right: 0.5rem;
}

.muted-text {
  color: #999999;
}

.doc-not-found {
  margin-left: 1rem;
  margin-top: 1rem;
}

button.button-as-link {
  padding: 0;
  margin: 0;
  line-height: 0;
}

.veil {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  opacity: 0.7;
  background-color: #fff;
  z-index: 99;
  text-align: center;
  display: flex;
  justify-content: center;
  /* align horizontal */
  align-items: center;
}

.veil-image {
  position: absolute;
  top: 100px;
  left: 240px;
  z-index: 100;
}

.highlight-matching-ph {
  background-color: black;
  color: yellow;
}

/*This is to fix the horizontal scroll that was
showing up when putting patient badge inside
react-window*/
.patient-badge-container>div {
  margin: 0;
  padding: 0;
}

.patient-badge-past {
  background-color: #f1f2f3;
  background-image: linear-gradient(rgba(0, 0, 0, 0.015) 1px, transparent 1px),
    linear-gradient(0, rgba(0, 0, 0, 0.015) 1px, transparent 1px);
  background-size: 5px 5px;
  background-position: -1px -1px, -1px -1px;
}

.patient-badge-future {
  background-color: #f6f8fa;
}

.patient-badge-action-button {
  margin-left: 0.2rem;
}

.filtered-hl {
  background-color: #ccff33;
  font-weight: 700;
}

.coupon {
  font-size: 18px;
}

.zoom {
  transition: 0.2s linear;
}

.zoom:hover {
  transform: scale(1.3);
}

.spinme {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.doc-new-tag {
  position: absolute;
  left: 10px;
  top: -10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px;
  border-radius: 3px;
  font-size: smaller;
}

.doc-rejected {
  background-color: #a00;
}

.doc-pending {
  background-color: hwb(39 0% 0% / 0.92);
}

.doc-new {
  background-color: #0a0;
}

.doc-unconfigured {
  border: 1px solid #a00 !important;
}

.doc-new-tag div.badge-text {
  display: inline-block;
  color: white;
}

.print-appts {
  /* width: 100%; */
  white-space: nowrap;
  empty-cells: show;
  width: 1% !important;
}

.print-appts>*>tr,
.print-appts>thead>tr>th,
.print-appts>*>tr>td {
  border-color: black !important;
  margin: 0 !important;
  padding: 2px !important;
  min-width: 1px !important;
  /* width: 0.1% !important; */
}

ul.pagination {
  display: inline-block;
  padding: 0;
  margin: 0;
}

ul.pagination li {
  display: inline;
}

ul.pagination li a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 5px;
}

ul.pagination li.selected a {
  background-color: #4caf50;
  color: white;
  border-radius: 5px;
}

.battery-container {
  width: 30px;
  height: 50px;
  background-color: #ddd;
  position: relative;
}

.battery-fill {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #4CAF50;
  transition: height 0.5s;
}

.toolbar {
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagination-container {
  display: flex;
  justify-content: center;
  flex: 1;
}

.download-button {
  margin-right: 5rem;
  margin-bottom: 2rem;
}

.error-message {
  position: absolute;
  top: 100%;
  margin-top: 0.5rem;
  color: red;
}

.bold {
  font-weight: bold;
}

.adoption-percentage {
  font-weight: bold;
  margin-top: 2rem;
  margin-right: 2rem;
}

.adoption-percentage-container {
  display: flex;
  align-items: center;
}

ul.pagination li:hover:not(.selected) a {
  background-color: #ddd;
}

.display-flex-right-align {
  display: flex;
  justify-content: flex-end;
}

.scissors {
  height: 22px;
  /* image height */
  width: 100%;
  margin: auto auto;
  /* background-image: url('/scissors.png'); */
  background-repeat: no-repeat;
  background-position: right;
  position: relative;
  overflow: hidden;
}

.scissors:after {
  content: '';
  position: relative;
  top: 50%;
  display: block;
  border-top: 1px dashed black;
  margin-top: -1px;
}

.scissors>img {
  height: 22px;
  position: absolute;
  right: 50%;
}

.weeks-of-month-container {
  border: 1px solid #cccccc;
  border-top: 0px;
  border-radius: 6px;
}

.weeks-of-month-input input[type='checkbox'] {
  display: inline-block;
  width: 1.2rem;
}

.weeks-of-month-input label {
  font-weight: normal;
}

.weeks-of-month-input:nth-child(even) {
  background-color: lavender;
}

.tree-select .dropdown-content {
  min-width: 250px;
}

.tree-select .dropdown-trigger {
  border-radius: 0.25rem;
}

.tree-select .dropdown-trigger>span:after {
  font-size: 12px;
  color: #555;
}

.tree-select .toggle {
  font: normal normal normal 12px/1 FontAwesome;
  color: #555;
}

.tree-select .toggle.collapsed::after {
  content: '➕';
}

.tree-select .toggle.expanded::after {
  content: '➖';
}

.tree-select .tag-item .search {
  border-bottom: none;
}

.tree-select .dropdown,
.dropdown-trigger {
  width: 100%;
}

.doc-form input[type='text'],
textarea {
  width: 100%;
}

@page {
  size: auto;
  margin: 25mm 10mm 25mm 10mm;
}

.print-modal input[type='text'],
input[type='number'] {
  width: 100%;
}

div.edited-slotproto {
  border-color: red;
  box-shadow: 0 0 4px 2px red;
}

.upload-dp {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-group-xs>.btn,
.btn.btn-xs,
div.btn-xs {
  padding: 0.25rem 0.4rem;
  font-size: 0.875rem;
  line-height: 0.5;
  border-radius: 0.2rem;
}

.weekly-sch-modal {
  max-height: 75vh;
  overflow-y: auto;
}

.doc-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.doc-list-header>h5 {
  margin: 0;
}

.c9-brand>span {
  padding: 0.3rem;
}

.c9-brand :nth-child(2n + 1) {
  border: 1px solid black;
}

.c9-brand :nth-child(2n) {
  border: 1px solid black;
  color: white;
  background-color: black;
}

.settings-page {
  display: grid;
  grid-template-columns: 200px 1fr;
}

.settings-sidebar {
  background-color: #f6f8fa;
  margin-right: 5px;
  min-height: 100vh;
  padding-top: 3rem;
  padding-left: 1rem;
}

.settings-main {
  margin: 1rem;
}

.hospital-form {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.hospital-form input {
  width: 100%;
}

.hospital-form>.row {
  margin-bottom: 0.4rem;
}

.doc-ban-head {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.doc-ban-head img {
  height: 150px;
  margin: 1rem;
}

.doc-ban-head h3 {
  padding: 0;
  margin: 0;
}

.doc-ban-head-container {
  width: 100%;
}

.doc-detail-banner {
  white-space: pre-line;
}

.doc-detail-banner> :nth-child(2n + 1) {
  background-color: #f6f8fa;
}

.doc-detail-banner>div {
  padding: 0.15rem 0.375rem;
}

.flex-space-btw {
  display: flex;
  justify-content: space-between;
}

.hospName {
  position: relative;
}

.hospName-tooltip {
  display: none;
  position: absolute;
  background-color: cornsilk;
  left: 0;
  padding: 3px;
  border: 1px solid black;
  z-index: 1000;
}

.hospName:hover .hospName-tooltip {
  display: block;
}

.modalSiteCode>div {
  width: 50%;
  display: inline-block;
}

.matchingHospital {
  margin-bottom: 1vw;
}

.matchingHospital:first-child {
  margin-top: 1vw;
}

.customerRxModalNotfn {
  display: flex;
  flex-direction: row;
  height: 300px;
  justify-content: center;
  align-items: center;
  margin-top: 13px;
}

.siteCodeWrapper {
  display: flex !important;
  align-items: center;
}

.siteCodeDiv {
  width: 100%;
}

.hospNameDiv {
  padding-left: 1vw;
}

.labScanHeader {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  grid-column-gap: 2rem;
  margin: 2rem 2rem 2rem 0rem;
}

.main_content {
  display: flex;
  justify-content: center;
}

.drag_drop_heading {
  display: flex;
  text-align: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.submit_btn {
  display: inline-block;
  padding: 10px 15px;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  box-shadow: 0 3px #248eff;
  outline: none;
}

.submit_btn:hover {
  background-color: #0367d3;
}

.submit_btn:active {
  transform: translateY(4px);
}

.submit_btn:focus {
  outline: none;
}

.cancel-text {
  cursor: pointer;
  font-size: 16px;
  color: #007bff;
}

.cancel-text:hover {
  text-decoration: underline;
}

.display-linebreak {
  white-space: pre-line;
}

.sub-container {
  width: 48%;
}

.coupon-heading {
  margin: 40px 0;
}

.coupon-code {
  width: 100%;
  font-size: 20px;
  font-weight: bold;
}

.coupon-details {
  display: flex;
  padding: 10px 10px 10px 10px;
  border-bottom: 1px solid #ddd;
}

.coupon-list-heading {
  width: 33%;
  font-weight: bold;
}

.full-width {
  width: 100%;
}

.width-11 {
  width: 11%;
}

.width-12 {
  width: 12%;
}

.width-15 {
  width: 15%;
}

.coupon-table {
  background-color: #f7f7f7;
  width: 100%;
  border-radius: 10px;
}

.coupon-card {
  background-color: #f7f7f7;
  width: 100%;
  margin: 0 auto;
  border-radius: 10px;
}

.exp-input {
  height: 37.98px;
}

.reports-date-height {
  height: 38px;
}

table.user-details td {
  padding-right: 10rem;
}

table.user-details span {
  font-weight: 700;
}

.outer {
  width: 15rem;
  margin: 1rem;
}

.date-field {
  height: 40px;
  line-height: 40px;
}